import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'
import onClickOutside from 'react-onclickoutside'

// Components
import BlackEye from '../../assets/eye--black.svg'

// Styles
import * as style from './index.module.css'

function Filter({
	id,
	label,
	menu,
	menuLabel,
	onSelectFilter,
	selectedFilter,
}) {
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	Filter[`handleClickOutside_${id}`] = useCallback(() => {
		setMenuIsOpen(false)
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (menuIsOpen) {
				if (!document.body.classList.contains('block-scroll')) {
					document.body.classList.add('block-scroll');
				}
			} else {
				if (document.body.classList.contains('block-scroll')) {
					document.body.classList.remove('block-scroll');
				}
			}
		}
	}, [menuIsOpen])

	const handleToggleMenu = () => {
		setMenuIsOpen(!menuIsOpen);
	}

	const handleSelectFilter = data => {
		onSelectFilter(data)
		setMenuIsOpen(false)
	}

	useEffect(() => {
		const close = e => {
			if (e.keyCode === 27){
				setMenuIsOpen(false)
			}
		}
		window.addEventListener('keydown', close)
		return () => window.removeEventListener('keydown', close)
	},[])

	return (
		<div className={ style.Filter }>
			<button
				className={ style.Toggle }
				onClick={ handleToggleMenu }
			>
				{ label }
			</button>
			{
				menuIsOpen ? (
					<button
						className={ style.CloseMenu }
						onClick={ () => setMenuIsOpen(false) }
					>
						&#x274C;
					</button>
				) : null
			}

			{
				menuIsOpen ? (
					<div className={ style.Menu }>
						<div className={ style.Label }>
							{ menuLabel }
						</div>
						{
							menu && menu.map(item => (
								<button
									className={ selectedFilter === item.id ? style.MenuItemSelected : style.MenuItem }
									key={ item.id }
									onClick={ () => handleSelectFilter(item) }
								>
									{ selectedFilter === item.id ? <BlackEye className={ style.Eye } /> : null }
									<div className={ style.MenuItemLabel }>
										{ item.label }
										{
											item.count ? (
												<svg
		 											className={ style.Badge }
		 											preserveAspectRatio="xMinYMin meet"
													viewBox="0 0 140 140"
												>
													<g>
														<circle r="50%" cx="50%" cy="50%" />
														<text x="50%" y="50%" textAnchor="middle" dy="0.3em">{ item.count }</text>
													</g>
												</svg>
											) : null
										}
									</div>
								</button>
							))
						}
					</div>
				) : null
			}
		</div>
	)
}

const clickOutsideConfig = {
	handleClickOutside: ({ props }) => Filter[`handleClickOutside_${props.id}`],
}

export default onClickOutside(Filter, clickOutsideConfig)

import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Blink from '../../assets/eye-blink.svg'

import * as style from './index.module.css'

const Header = () => (
	<>
		<hgroup className={ style.Header }>
			<h1>
				Best
				<span className={ style.LineBreak } />
				H
				<span className={ style.Eye }>
					<span className={ style.EyeWrapperLeft }><Blink /></span>
					o
				</span>rr
				<span className={ style.Eye }>
					<span className={ style.EyeWrapperRight }><Blink /></span>
					o
				</span>r
				<span className={ style.LineBreak } />
				Scenes
			</h1>
		</hgroup>
		<div className={ style.Body }>
			<div className={ style.Interior }>
				<p>An ever growing collection featuring some of the best scenes in horror.</p>
				<p>“Best Horror Scenes” is a collection of scenes I feel are some of the most affecting in horror. Some may be simple black cat scares, others may be more subdued or nuanced. Many come from films that aren't necessarily “horror” but have elements or threads of horror.</p>
				<nav className={ style.Navigation }>
					<OutboundLink
						href="https://confirmsubscription.com/h/d/E638ABACCA3ADA9B"
						rel="noopener noreferrer"
						target="_blank"
					>
						Newsletter
					</OutboundLink>
					<OutboundLink
						href="https://besthorrorscenes.typeform.com/to/cJgjcf"
						rel="noopener noreferrer"
						target="_blank"
					>
						Suggest a Scene
					</OutboundLink>
					<OutboundLink
						href="https://www.youtube.com/c/besthorrorscenes"
						rel="noopener noreferrer"
						target="_blank"
					>
						YouTube
					</OutboundLink>
					<OutboundLink href="https://twitter.com/besthorrorscene">
						Twitter
					</OutboundLink>
					<OutboundLink href="mailto:brandon@besthorrorscenes.com">
						Contact
					</OutboundLink>
					<OutboundLink href="/rss.xml">
						RSS
					</OutboundLink>
					<OutboundLink href="https://letterboxd.com/brandondurham/">
						Letterboxd
					</OutboundLink>
					<p className={ style.Byline }>
						Best Horror Scenes is a love letter to the Horror film genre by Brandon Durham. Most film clips contain major spoilers, and it is recommended you don’t watch the clip if you plan on seeing the film.
					</p>
				</nav>
			</div>
		</div>
	</>
)

export default Header

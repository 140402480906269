/*
	everything
	2000s
	1990s
	1980s
	1970s
	pre-1970

	aliens
	based-on-a-true-story
	body-horror
	cult
	creepy-kids
	folk-horror
	found-footage
	ghosts
	giallo
	good-movies-to-play-in-the-background-at-a-party
	gore
	home-invasion
	jump-scare
	mockumentary
	new-french-extremity
	paranormal
	possession
	psychological
	revenge
	slasher
	suspense
	vampires
	weird-science
	zombies
*/

export default {
	filter: [
		{
			id: 'everything',
			label: 'everything',
		},
		{
			id: '2000s',
			label: '2000s',
		},
		{
			id: '1990s',
			label: '1990s',
		},
		{
			id: '1980s',
			label: '1980s',
		},
		{
			id: '1970s',
			label: '1970s',
		},
		{
			id: 'pre-1970',
			label: 'pre 1970',
		},
		{
			id: 'aliens',
			label: 'aliens',
		},
		{
			id: 'based-on-a-true-story',
			label: 'based on a true story',
		},
		{
			id: 'body-horror',
			label: 'body horror',
		},
		{
			id: 'creepy-kids',
			label: 'creepy kids',
		},
		{
			id: 'cult',
			label: 'cult',
		},
		{
			id: 'folk-horror',
			label: 'folk horror',
		},
		{
			id: 'found-footage',
			label: 'found footage',
		},
		{
			id: 'ghosts',
			label: 'ghosts',
		},
		{
			id: 'giallo',
			label: 'giallo',
		},
		// {
		// 	id: 'good-movies-to-play-in-the-background-at-a-party',
		// 	label: 'good movies to play in the background at a party',
		// },
		{
			id: 'gore',
			label: 'gore',
		},
		{
			id: 'home-invasion',
			label: 'home invasion',
		},
		{
			id: 'jump-scare',
			label: 'jump scare',
		},
		{
			id: 'mockumentary',
			label: 'mockumentary',
		},
		{
			id: 'new-french-extremity',
			label: 'new french extremity',
		},
		{
			id: 'paranormal',
			label: 'paranormal',
		},
		{
			id: 'possession',
			label: 'possession',
		},
		{
			id: 'psychological',
			label: 'psychological',
		},
		{
			id: 'revenge',
			label: 'revenge',
		},
		{
			id: 'slasher',
			label: 'slasher',
		},
		{
			id: 'supernatural',
			label: 'supernatural',
		},
		{
			id: 'suspense',
			label: 'suspense',
		},
		{
			id: 'vampires',
			label: 'vampires',
		},
		{
			id: 'weird-science',
			label: 'weird science',
		},
		{
			id: 'zombies',
			label: 'zombies',
		},
	],
	sort: [
		{
			id: 'post-date',
			label: 'post date',
		},
		{
			id: 'random',
			label: 'random',
		},
		{
			id: 'release-year',
			label: 'release year',
		},
		{
			id: 'title',
			label: 'title',
		},
		{
			id: 'votes',
			label: 'votes',
		},
	],
	sortDirection: [
		{
			id: 'ascending',
			label: 'ascending',
		},
		{
			id: 'descending',
			label: 'descending',
		},
	],
}

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9BX83MYP9T"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"rgb(231, 72, 60)","display":"standalone","icon":"src/images/icon.png","name":"Best Horror Scenes","short_name":"Best Horror Scenes","start_url":"/","theme_color":"rgb(231, 72, 60)","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ebc20e8616b42c12d64da568b5257229"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import { shuffle } from 'lodash'

export default shuffle([
	'static-0.jpg',
	'static-1.jpg',
	'static-2.jpg',
	'static-3.jpg',
	'static-4.jpg',
	'static-5.jpg',
	'static-6.jpg',
	'static-7.jpg',
	'static-8.jpg',
	'static-9.jpg',
	'static-10.jpg',
	'static-11.jpg',
	'static-12.jpg',
	'static-13.jpg',
	'static-14.jpg',
	'static-15.jpg',
	'static-16.jpg',
	'static-17.jpg',
])

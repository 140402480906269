/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { sortBy } from  'lodash';

import * as style from './index.module.css'

const MAX_COMMENT_LENGTH = 666

const CommentForm = ({
	entries,
	entry,
	onClose,
	onSendComment,
	selectedEntry,
}) => {
	const selectFilm = sortBy(entries, [entry => entry.node.frontmatter.title.toLowerCase()]).map(entry => ({
		label: `${entry?.node?.frontmatter?.title} (${entry?.node?.frontmatter?.year})`,
		value: entry?.node?.frontmatter?.index,
	}))

	const {
		formState: {
			errors,
			isSubmitSuccessful,
			isSubmitting,
		},
		handleSubmit,
		register,
		watch,
	} = useForm({
		defaultValues: { film: selectedEntry },
	});

	const [comment, rating] = watch(['comment', 'rating'])

	const ratingClasses = [style.NumberInput]
	if (!rating || rating < 10) { ratingClasses.push(style.NumberInput__singleDigit) }

	useEffect(() => {
		const close = e => {
			if (e.keyCode === 27){
				onClose()
			}
		}
		window.addEventListener('keydown', close)
		return () => window.removeEventListener('keydown', close)
	},[])

	return (
		<form
			noValidate
			className={ style.CommentFormWrapper }
			onSubmit={ handleSubmit(onSendComment) }
		>
			<button
				className={ style.CloseMenu }
				onClick={ onClose }
			>
				&#x274C;
			</button>
			{
				isSubmitSuccessful ? (
					<div className={ style.CommentForm }>
						Thank you!<br />Your comment will be posted after review.
						<br />
						<button className={ style.Button__Close } onClick={ onClose }>Close.</button>
					</div>
				) : (
					<>
						<div className={ style.CommentForm }>
							<div className={ style.InputWrapper }>
								<label
									className={ style.InputLabel }
									htmlFor="film"
								>
									Film:
								</label>
								{
									entry ? (
										<div className={ style.Input }>
											{ entry?.title }
										</div>
									) : (
										<select
											className={ style.Input__withWidth }
											disabled={ isSubmitting }
											id="film"
											{ ...register('film') }
										>
											{
												selectFilm && selectFilm.map(film => (
													<option key={ film.value } value={ film.value }>{ film.label }</option>
												))
											}
										</select>
									)
								}
							</div>
							<div className={ style.InputWrapper }>
								<label
									className={ errors?.name ? [style.InputLabel, style.InputLabel__required, style.InputLabel__withError].join(' ') : [style.InputLabel, style.InputLabel__required].join(' ') }
									htmlFor="name"
								>
									Name:
								</label>
								<input
									className={ style.Input__withWidth }
									disabled={ isSubmitting }
									id="name"
									placeholder="Required"
									type="text"
									{ ...register('name', { required: 'Name is required' }) }
								/>
							</div>
							{ /*
							<div className={ style.InputWrapper }>
								<label
									className={ errors?.email ? [style.InputLabel, style.InputLabel__withError].join(' ') : style.InputLabel }
									htmlFor="email"
								>
									E-mail
								</label>
								<input
									className={ style.Input__withWidth }
									disabled={ isSubmitting }
									id="email"
									type="email"
									{ ...register('email', {
										pattern: {
											message: 'Does not match email format',
											value: /\S+@\S+\.\S+/,
										},
									}) }
								/>
							</div>
							*/ }
							<div className={ style.InputWrapper }>
								<label
									className={ errors?.rating ? [style.InputLabel, style.InputLabel__withError].join(' ') : style.InputLabel }
									htmlFor="rating"
								>
									Rating:
								</label>
								<input
									className={ ratingClasses.join(' ') }
									disabled={ isSubmitting }
									id="rating"
									max={ 10 }
									min={ 0 }
									placeholder={ 1 }
									type="number"
									{
										...register('rating', {
											max: 10,
											min: 0,
											pattern: '[0-9]*',
										})
									}
									onKeyPress={ event => {
										if (!/[0-9]/.test(event.key)) { event.preventDefault(); }
									} }
								/>
								<span className={ isSubmitting ? style.Unit__Inline__disabled : style.Unit__Inline }>/10</span>
							</div>
							<div className={ style.InputWrapper }>
								<label
									className={ errors?.comment ? [style.InputLabel, style.InputLabel__required, style.InputLabel__withError].join(' ') : [style.InputLabel, style.InputLabel__required].join(' ') }
									htmlFor="comment"
								>
									Comment:
								</label>
								<TextareaAutosize
									className={ style.Input }
									disabled={ isSubmitting }
									id="comment"
									maxLength={ MAX_COMMENT_LENGTH }
									placeholder="Required"
									{
										...register('comment', {
											maxLength: {
												message: 'Comment cannot exceed 20 characters',
												value: MAX_COMMENT_LENGTH,
											},
											required: 'Comment is required',
											validate: value => value.trim().length > 0,
										})
									}
								/>
								<span className={ isSubmitting ? style.Unit__disabled : style.Unit }>{ comment ? comment.trim()?.length : 0 }/{ MAX_COMMENT_LENGTH }</span>
							</div>
						</div>
						<footer className={ style.Footer }>
							<ul className={ style.Notice }>
								<li>Required.</li>
								<li>Your Name and Rating will be displayed with your comment.</li>
							</ul>
							<button
								className={ style.Button }
								disabled={ isSubmitting }
								type="submit"
							>
								Submit
							</button>
						</footer>
					</>
				)
			}
		</form>
	)
}

export default CommentForm

const isWindow = typeof window !== 'undefined' && window

import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'
import PropTypes from 'prop-types'
const {
	AnonymousCredential,
	Stitch,
	RemoteMongoClient,
} = isWindow && require('mongodb-stitch-browser-sdk')

// Config
import FILTERS from '../../filters';

// Components
import CommentForm from '../CommentForm'
import Header from '../Header'
import Filter from '../Filter'

// Graphics
import BlackEye from '../../assets/eye--black.svg'
import Holders from '../../holders'

// Styles
import * as style from './index.module.css'
// import './onesignal.css'
import '../base.css'

// Set up MongoDB
let client = null
let db = null
if (isWindow && !Stitch.hasAppClient()) {
	client = Stitch.initializeDefaultAppClient('besthorrorscenes-mdzju')
	const mongodb = client.getServiceClient(
		RemoteMongoClient.factory,
		'mongodb-atlas'
	)
	db = mongodb.db('besthorrorscenes')
}

const Layout = ({ children }) => {
	// Push notification state
	const [supportsPush, setSupportsPush] = useState(false)
	const [isBraveBrowser, setIsBraveBrowser] = useState(false)

	const [isFilmComment, setFilmComment] = useState(null)
	const [comments, setComments] = useState([])
	const [likes, setLikes] = useState([])
	const [filters, setFilters] = useState({
		filter: 'everything',
		sort: 'random',
		sortDirection: 'descending',
	})
	const [showSpoilers, setShowSpoilers] = useState(true)

	useEffect(async () => {
		setIsBraveBrowser(navigator.brave && await navigator.brave.isBrave() || false);
	}, []);

	useEffect(() => {
		if (OneSignal) {
			OneSignal.push(() => {
				const isPushSupported = OneSignal.isPushNotificationsSupported();
				setSupportsPush(isPushSupported);

				OneSignal.isPushNotificationsEnabled(isEnabled => {
					if (isEnabled)
						console.log("Push notifications are enabled!");
					else
						console.log("Push notifications are not enabled yet.");
				});
			});
		}
	}, []);

	// Log into MongoDB as anonymous user
	useEffect(() => {
		if (isWindow) {
			client.auth
				.loginWithCredential(new AnonymousCredential())
				.then(() => {
					getComments()
					getLikes()
				})
		}
	}, [])

	// Reach out to MongoDB to get likes for all films
	const getLikes = () => {
		db
			.collection('likes')
			.find({}, { limit: 1000 })
			.asArray()
			.then(data => {
				setLikes(data)
			})
	}

	// Update film record with user’s vote
	const setLike = (filmId, isLiked) => {
		if (typeof window !== 'undefined') {
			const lsValues = JSON.parse(window.localStorage.getItem('bhsLikes')) || [];
			const savedValue = lsValues ? lsValues.filter(like => like.filmId === filmId) : []
			if (savedValue.length === 0) {
				window.localStorage.setItem('bhsLikes', JSON.stringify([...lsValues, { filmId, isLiked }]))
			}
		}

		db
			.collection('likes')
			.insertOne({
				filmId,
				isLiked,
				owner_id: client.auth.user.id,
			})
			.then(getLikes)
			.catch(console.error);
	}

	// Reach out to MongoDB to get comments for all films
	const getComments = () => {
		db
			.collection('comments')
			.find({}, { limit: 1000 })
			.asArray()
			.then(data => {
				setComments(data)
			})
	}

	// Update film record with user’s vote
	const setComment = ({
		comment,
		name,
		film,
		rating,
	}) => {
		if (window !== 'undefined') {
			window.gtag('event', 'comment', {
				action: 'Submit',
				label: film,
			});
		}
		return db
			.collection('comments')
			.insertOne({
				comment: comment.substring(0, 666),
				date: new Date(),
				name,
				film,
				isApproved: false,
				owner_id: client.auth.user.id,
				rating,
			})
			.then(getComments)
			.catch(console.error)
	}

	const handleSelectFilter = data => {
		const { action, id } = data
		const curFilters = { ...filters }
		curFilters[action] = id
		setFilters(curFilters)
		if (isWindow) { window.scrollTo({ behavior: 'smooth', top: 0 }); }

		if (window !== 'undefined') {
			window.gtag('event', 'filters', {
				action,
				label: id,
			});
		}
	}

	useEffect(() => {
		if (isFilmComment === null) {
			if (document.body.classList.contains('block-scroll')) {
				document.body.classList.remove('block-scroll');
			}
		} else {
			if (!document.body.classList.contains('block-scroll')) {
				document.body.classList.add('block-scroll');
			}
		}
	}, [isFilmComment])

	// Load social-related scripts
	useEffect(
		() => {
			setShowSpoilers(localStorage.getItem('showSpoilers'))

			// Load YouTube script
			const ytScript = document.createElement('script')
			ytScript.setAttribute('crossOrigin', 'anonymous')
			ytScript.setAttribute('src', '//apis.google.com/js/platform.js')
			document.body.appendChild(ytScript)

			const twScript = document.createElement('script')
			twScript.setAttribute('crossOrigin', 'anonymous')
			twScript.setAttribute('src', '//platform.twitter.com/widgets.js')
			document.body.appendChild(twScript)
		},
		[],
	)

	// Pass Mongo-related props to children
	const childrenWithProps = React.Children.map(
		children,
		(child) => React.cloneElement(child, {
			client,
			comments,
			db,
			filters,
			isBraveBrowser,
			isFilmComment,
			likes,
			setComment: setFilmComment,
			setLike,
			supportsPush,
		})
	);

	const selectedFilter = FILTERS.filter.find(filter => filter.id === filters.filter)
	const selectedSort = FILTERS.sort.find(sort => sort.id === filters.sort)
	const selectedSortDirection = FILTERS.sortDirection.find(srtDirection => srtDirection.id === filters.sortDirection)
	const isIndexPage = children?.props?.path === '/'
	const entries = children?.props?.data?.allMarkdownRemark?.edges
	const entry = children?.props?.data?.markdownRemark?.frontmatter

	return (
		<>
		{ /*
		<div id="onesignal-slidedown-container" className="onesignal-slidedown-container onesignal-reset slide-down">
		   <div id="onesignal-slidedown-dialog" className="onesignal-slidedown-dialog">
		      <div id="normal-slidedown">
		         <div className="slidedown-body" id="slidedown-body">
		            <div className="slidedown-body-icon"><img alt="notification icon" src="https://img.onesignal.com/permanent/7d30f1de-c40f-4f01-84f9-6e066f36ece0" /></div>
		            <div className="slidedown-body-message">Get notified by email and/or text every time a new scene is published.</div>
		            <div className="clearfix"></div>
		            <div id="onesignal-loading-container"></div>
		            <div className="channel-capture-container" id="channel-capture-container">
		               <div className="input-with-validation-element" id="email-input-with-validation-element">
		                  <label title="Email Address" htmlFor="onesignal-email-input">Email Address</label>
		                  <div style={ { clear: 'both' } }></div>
		                  <input type="email" id="onesignal-email-input" tabIndex="1" className="onesignal-email-input" />
		                  <div style={ { clear: 'both' } }></div>
		                  <div className="onesignal-validation-element-hidden onesignal-validation-element" id="onesignal-email-validation-element">
		                     <img src="data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.98775 -0.00114406C5.85015 0.0338508 3.81219 0.908665 2.31442 2.43419C1.565 3.18031 0.973715 4.06987 0.575897 5.04969C0.17808 6.02952 -0.0180997 7.07949 -0.000914196 8.13686C-0.00214385 9.17005 0.200528 10.1933 0.595487 11.148C0.990446 12.1028 1.56993 12.9702 2.30072 13.7005C3.03151 14.4309 3.89925 15.0098 4.85421 15.4042C5.80916 15.7986 6.83256 16.0007 7.86575 15.9989H8.00842C10.1467 15.9769 12.1889 15.1075 13.6869 13.5816C15.185 12.0557 16.0165 9.99781 15.9991 7.85952C16.0015 6.8138 15.7949 5.77814 15.3913 4.81345C14.9876 3.84876 14.3952 2.97451 13.6488 2.24213C12.9023 1.50974 12.017 0.933994 11.0448 0.548751C10.0726 0.163508 9.03324 -0.0234551 7.98775 -0.00114406ZM6.99909 11.0269C6.99428 10.8961 7.01558 10.7658 7.06175 10.6434C7.10792 10.521 7.17803 10.4091 7.26797 10.3141C7.35792 10.2191 7.4659 10.143 7.58559 10.0903C7.70529 10.0375 7.8343 10.0092 7.96509 10.0069H7.98309C8.24616 10.0074 8.49882 10.1097 8.6881 10.2924C8.87739 10.4751 8.9886 10.724 8.99842 10.9869C9.00331 11.1176 8.98207 11.248 8.93594 11.3704C8.8898 11.4928 8.8197 11.6048 8.72974 11.6998C8.63978 11.7948 8.53176 11.8709 8.41202 11.9236C8.29229 11.9763 8.16323 12.0046 8.03242 12.0069H8.01442C7.75145 12.006 7.49897 11.9036 7.30976 11.721C7.12054 11.5383 7.00923 11.2896 6.99909 11.0269ZM7.33242 8.33219V4.33219C7.33242 4.15538 7.40266 3.98581 7.52768 3.86079C7.65271 3.73576 7.82227 3.66552 7.99909 3.66552C8.1759 3.66552 8.34547 3.73576 8.47049 3.86079C8.59551 3.98581 8.66575 4.15538 8.66575 4.33219V8.33219C8.66575 8.509 8.59551 8.67857 8.47049 8.80359C8.34547 8.92862 8.1759 8.99886 7.99909 8.99886C7.82227 8.99886 7.65271 8.92862 7.52768 8.80359C7.40266 8.67857 7.33242 8.509 7.33242 8.33219Z' fill='%23E54B4D'/%3e%3c/svg%3e" />
		                     <p>Please enter a valid email</p>
		                  </div>
		               </div>
		               <div className="input-with-validation-element" id="sms-input-with-validation-element">
		                  <label title="Phone Number" htmlFor="iti-onesignal-sms-input">Phone Number</label>
		                  <div style={ { clear: 'both' } }></div>
		                  <div className="iti iti--allow-dropdown iti--separate-dial-code">
		                     <div className="iti__flag-container">
		                        <div className="iti__selected-flag" role="combobox" aria-controls="iti-0__country-listbox" aria-owns="iti-0__country-listbox" aria-expanded="false" tabIndex="0" title="United States: +1" aria-activedescendant="iti-0__item-us-preferred">
		                           <div className="iti__flag iti__us"></div>
		                           <div className="iti__selected-dial-code">+1</div>
		                           <div className="iti__arrow"></div>
		                        </div>
		                        <ul className="iti__country-list iti__hide" id="iti-0__country-listbox" role="listbox" aria-label="List of countries">
		                           <li className="iti__country iti__preferred iti__active" tabIndex="-1" id="iti-0__item-us-preferred" role="option" data-dial-code="1" data-country-code="us" aria-selected="true">
		                              <div className="iti__flag-box">
		                                 <div className="iti__flag iti__us"></div>
		                              </div>
		                              <span className="iti__country-name">United States</span><span className="iti__dial-code">+1</span>
		                           </li>
		                        </ul>
		                     </div>
		                     <input type="tel" id="iti-onesignal-sms-input" tabIndex="2" className="iti-onesignal-sms-input" autoComplete="off" data-intl-tel-input-id="0" style={ { paddingLeft: 75 } } />
		                  </div>
		                  <div style={ { clear: 'both' } }></div>
		                  <div className="onesignal-validation-element-hidden onesignal-validation-element" id="onesignal-sms-validation-element">
		                     <img src="data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.98775 -0.00114406C5.85015 0.0338508 3.81219 0.908665 2.31442 2.43419C1.565 3.18031 0.973715 4.06987 0.575897 5.04969C0.17808 6.02952 -0.0180997 7.07949 -0.000914196 8.13686C-0.00214385 9.17005 0.200528 10.1933 0.595487 11.148C0.990446 12.1028 1.56993 12.9702 2.30072 13.7005C3.03151 14.4309 3.89925 15.0098 4.85421 15.4042C5.80916 15.7986 6.83256 16.0007 7.86575 15.9989H8.00842C10.1467 15.9769 12.1889 15.1075 13.6869 13.5816C15.185 12.0557 16.0165 9.99781 15.9991 7.85952C16.0015 6.8138 15.7949 5.77814 15.3913 4.81345C14.9876 3.84876 14.3952 2.97451 13.6488 2.24213C12.9023 1.50974 12.017 0.933994 11.0448 0.548751C10.0726 0.163508 9.03324 -0.0234551 7.98775 -0.00114406ZM6.99909 11.0269C6.99428 10.8961 7.01558 10.7658 7.06175 10.6434C7.10792 10.521 7.17803 10.4091 7.26797 10.3141C7.35792 10.2191 7.4659 10.143 7.58559 10.0903C7.70529 10.0375 7.8343 10.0092 7.96509 10.0069H7.98309C8.24616 10.0074 8.49882 10.1097 8.6881 10.2924C8.87739 10.4751 8.9886 10.724 8.99842 10.9869C9.00331 11.1176 8.98207 11.248 8.93594 11.3704C8.8898 11.4928 8.8197 11.6048 8.72974 11.6998C8.63978 11.7948 8.53176 11.8709 8.41202 11.9236C8.29229 11.9763 8.16323 12.0046 8.03242 12.0069H8.01442C7.75145 12.006 7.49897 11.9036 7.30976 11.721C7.12054 11.5383 7.00923 11.2896 6.99909 11.0269ZM7.33242 8.33219V4.33219C7.33242 4.15538 7.40266 3.98581 7.52768 3.86079C7.65271 3.73576 7.82227 3.66552 7.99909 3.66552C8.1759 3.66552 8.34547 3.73576 8.47049 3.86079C8.59551 3.98581 8.66575 4.15538 8.66575 4.33219V8.33219C8.66575 8.509 8.59551 8.67857 8.47049 8.80359C8.34547 8.92862 8.1759 8.99886 7.99909 8.99886C7.82227 8.99886 7.65271 8.92862 7.52768 8.80359C7.40266 8.67857 7.33242 8.509 7.33242 8.33219Z' fill='%23E54B4D'/%3e%3c/svg%3e" />
		                     <p>Please enter a valid phone number</p>
		                  </div>
		               </div>
		            </div>
		         </div>
		         <div className="slidedown-footer" id="slidedown-footer">
		            <button className="align-right primary slidedown-button" id="onesignal-slidedown-allow-button">Submit</button><button className="align-right secondary slidedown-button" id="onesignal-slidedown-cancel-button">No Thanks</button>
		            <div className="clearfix"></div>
		         </div>
		      </div>
		   </div>
		</div>
		*/ }
			{ !showSpoilers ? (
				<section className={ style.Spoilers }>
					<div id="fb-root" />
					<BlackEye />
					<p>This site features clips from horror films that most assuredly contain spoilers. Consider yourself warned.</p>
					<button
						className={ style.Okay }
						onClick={ () => {
							localStorage.setItem('showSpoilers', true)
							setShowSpoilers(true)
							if (window !== 'undefined') {
								window.gtag('event', 'notifications', {
									action: 'Click',
									label: 'Show Spoilers',
								});
							}
						} }
					>
						I’m okay with spoilers!
					</button>
				</section>
			) : null }
			{
				isFilmComment !== null ? (
					<CommentForm
						entries={ entries }
						entry={ entry }
						onClose={ () => setFilmComment(null) }
						onSendComment={ setComment }
						selectedEntry={ isFilmComment }
					/>
				) : null
			}
			<header className={ style.Header }>
				<Header />
			</header>
			{
				isIndexPage ? (
					<nav className={ style.FilterBar } id="filter-bar">
						<div className={ style.Filters }>
							<span>Currently viewing</span>
							<Filter
								id="type"
								label={ selectedFilter.label }
								menu={
									FILTERS.filter.map(filter => {
										let entriesWithThisFilter = entries
										switch (filter.id) {
											case '2000s':
												entriesWithThisFilter = entries.filter(entry => entry.node.frontmatter.year > 1999)
												break
											case '1990s':
												entriesWithThisFilter = entries.filter(entry => entry.node.frontmatter.year > 1989 && entry.node.frontmatter.year < 2000)
												break
											case '1980s':
												entriesWithThisFilter = entries.filter(entry => entry.node.frontmatter.year > 1979 && entry.node.frontmatter.year < 1990)
												break
											case '1970s':
												entriesWithThisFilter = entries.filter(entry => entry.node.frontmatter.year > 1969 && entry.node.frontmatter.year < 1980)
												break
											case 'pre-1970':
												entriesWithThisFilter = entries.filter(entry => entry.node.frontmatter.year < 1970)
												break
											default:
												entriesWithThisFilter = entries.filter(entry => entry?.node?.frontmatter?.genres && entry?.node?.frontmatter?.genres.includes(filter?.id))
										}
										return {
											...filter,
											action: 'filter',
											count: filter.id === 'everything' ? entries?.length : entriesWithThisFilter?.length,
										}
									})
								}
								menuLabel="filter by"
								onSelectFilter={ handleSelectFilter }
								selectedFilter={ selectedFilter.id }
							/>
							<span>sorted</span>
							<span>by</span>
							<Filter
								id="sort"
								label={ selectedSort.label }
								menu={
									FILTERS.sort.map(filter => ({
										...filter,
										action: 'sort',
									}))
								}
								menuLabel="sort by"
								onSelectFilter={ handleSelectFilter }
								selectedFilter={ selectedSort.id }
							/>
							{
								selectedSort.label !== 'random' ? (
									<>
										<span>in</span>
										<Filter
											id="direction"
											label={ selectedSortDirection.label }
											menu={
												FILTERS.sortDirection.map(filter => ({
													...filter,
													action: 'sortDirection',
												}))
											}
											menuLabel="sort order"
											onSelectFilter={ handleSelectFilter }
											selectedFilter={ selectedSortDirection.id }
										/>
										<span>order</span>
									</>
								) : null
							}
							<span>.</span>
						</div>
					</nav>
				) : null
			}
			<main className={ style.Content }>
				<section>{ childrenWithProps }</section>
				<footer className={ style.VideosFooter }>
					<div
						className="g-ytsubscribe"
						data-channelid="UCcUG_caM0mb8jbch8JPTN5w"
						data-count="default"
						data-layout="default"
					/>
					<a
						className="twitter-follow-button"
						data-show-count="false"
						data-size="large"
						href="https://twitter.com/besthorrorscene?ref_src=twsrc%5Etfw"
					>
						Follow @besthorrorscene
					</a>
				</footer>
			</main>
			<div style={ { display: 'none' } }>
				{ Holders.map(holder => <img key={ holder } src={ `/${holder}` } />) }
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
